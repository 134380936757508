.burger-close {
  display: none;
}
.icon,
.icon-close {
  color: white;
}
.icon-close {
  margin: 20px 0 20px 15px;
}

@media screen and (max-width: 768px) {
  .burger {
    z-index: 1000;
    flex-flow: column nowrap;
    background-color: #af030d;
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 60vw;
    transition: transform 0.3s ease-in-out;
  }
}

@media screen and (max-width: 480px) {
  .body__img {
    height: 170px;
  }
}
