* {
  font-family: 'Quicksand', sans-serif;
  margin: 0px;
  box-sizing: border-box;
  text-decoration: none;
}

.header,
.none {
  height: 70px;
  margin-bottom: 35px;
  background-color: #af030d;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.5);
  position: sticky;
  top: 0px;
  z-index: 999;

  img {
    height: 60px;
    border-radius: 5px;
  }
  &-user {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    ul {
      position: absolute;
      top: 30px;
      list-style: none;
      position: absolute;
      top: 30px;
      list-style: none;
      padding: 10px;
      width: 140px;
      background-color: #af030d;
      border-radius: 5px;
      display: none;
      li {
        margin: 0;
        text-align: center;
      }
    }
    &-name {
      display: flex;
      align-items: center;
      span {
        margin-top: 1px;
      }
    }
  }
  &-user:hover {
    ul {
      display: block;
    }
  }
  &-nav {
    color: white;
  }
  &-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;

    &-link {
      color: white;
    }

    li {
      margin-right: 20px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding: 0 15px;
    justify-content: space-between;
    &-user {
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      ul {
        top: 50px;
        width: 180px;
        padding: 0;
        display: block;
      }
      &-name {
        display: flex;
        align-items: center;
        span {
          margin-top: 1px;
        }
      }
    }
  }
  .header-nav {
    flex-direction: column;
    align-items: flex-start;
    li {
      padding: 15px 0;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 480px) {
  .header-user {
    ul {
      top: 90px;
    }
  }
}
