h2 {
  font-family: 'Roboto';
  text-align: center;
  color: rgba(175, 3, 13, 1);
}

.container {
  width: 350px;
  position: absolute;
  top: 380px;
  left: 50%;
  transform: translate(-50%, -50%);

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;

    &-spinner {
      margin-top: 28px;
    }

    &-label {
      margin: 20px 0 3px;
      font-size: 12px;
      color: rgba(175, 3, 13, 1);
    }

    &-fields {
      height: 20px;
      font-size: 16px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      display: inline-block;
      width: 100%;
      cursor: pointer;
      padding: 7px 10px;
      height: 42px;
      outline: 0;
      border: 0;
      border-radius: 0;
      background: white;
      color: black;
      font-size: 1em;
      font-family: 'Quicksand', sans-serif;
      border: 2px solid rgba(0, 0, 0, 0.2);
      border-radius: 12px;
      position: relative;
      transition: all 0.25s ease;

      &:hover {
        border: 2px solid #af030d;
      }
      &-upperscase {
        text-transform: uppercase;
      }
    }

    &-file {
      visibility: hidden;
    }

    &-uploader {
      margin: 30px 0 5px;
      border: 2px solid rgba(0, 0, 0, 0.2);
      padding: 7px 10px;
      border-radius: 12px;
      cursor: pointer;
      text-align: center;

      &:hover {
        border: 2px solid rgba(175, 3, 13, 1);
      }
    }

    &-submit,
    &-submit-disabled {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      display: inline-block;
      width: 100%;
      cursor: pointer;
      padding: 7px 10px;
      height: 42px;
      outline: 0;
      border: 0;
      border-radius: 0;
      background: rgba(175, 3, 13, 1);
      color: white;
      font-size: 1em;
      font-family: 'Quicksand', sans-serif;
      border: 2px solid rgba(175, 3, 13, 1);
      border-radius: 50px;

      &-disabled {
        background: rgba(175, 3, 13, 0.5);
        border: 2px solid rgba(175, 3, 13, 0);
        cursor: default;
      }
    }

    &-submit-margin {
      margin-top: 26px;
    }

    &-show {
      background-color: white;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 9px;
      right: 5px;

      &:hover {
        color: rgba(175, 3, 13, 1);
      }
    }

    &-pass {
      position: relative;
    }

    &-error {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-left: 10px;
      color: rgba(175, 3, 13, 1);

      span {
        margin-left: 3px;
      }
    }
  }
}
