h2 {
  font-family: 'Roboto';
  text-align: center;
  color: rgba(175, 3, 13, 1);
}

.icon-down {
  border: none;
  background-color: white;
}

.search {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;

  .container {
    width: 200px;
    display: flex;
    align-items: center;
    flex-direction: column;
    img {
      height: 200px;
      margin-top: 80px;
    }

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;

      &-spinner {
        margin-top: 20px;
      }
      &-label {
        margin: 20px 0 3px;
        font-size: 12px;
        color: rgba(175, 3, 13, 1);
      }

      &-check {
        margin-top: 20px;
        font-size: 16px;

        input {
          margin-right: 5px;
        }
      }

      &-fields {
        height: 20px;
        font-size: 16px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        display: inline-block;
        width: 100%;
        cursor: pointer;
        padding: 7px 10px;
        height: 42px;
        outline: 0;
        border: 0;
        border-radius: 0;
        background: white;
        color: black;
        font-size: 1em;
        font-family: 'Quicksand', sans-serif;
        border: 2px solid rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        position: relative;
        transition: all 0.25s ease;

        &:hover {
          border: 2px solid #af030d;
        }

        &-upperscase {
          text-transform: uppercase;
        }
      }

      &-submit,
      &-submit-disabled {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        display: inline-block;
        width: 100%;
        cursor: pointer;
        padding: 7px 10px;
        height: 42px;
        outline: 0;
        border: 0;
        border-radius: 0;
        background: rgba(175, 3, 13, 1);
        color: white;
        font-size: 1em;
        font-family: 'Quicksand', sans-serif;
        border: 2px solid rgba(175, 3, 13, 1);
        border-radius: 50px;

        &-disabled {
          background: rgba(175, 3, 13, 0.5);
          border: 2px solid rgba(175, 3, 13, 0);
          cursor: default;
        }
      }

      &-submit-margin {
        margin-top: 26px;
      }

      &-error {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-left: 10px;
        color: rgba(175, 3, 13, 1);

        span {
          margin-left: 3px;
        }
      }
    }
  }
}

.table {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-delete {
  color: rgba(175, 3, 13, 1);
}

.info-container{
  display: flex;
  align-items: flex-start;
  margin-top: 16px;
  padding: 14px 12px;
  border: 1px solid rgb(0, 0, 0, 0.1);
  border-left: 6px solid #007aff;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.03);
  &-text{
    font-size: 12px;
    font-weight: 200;
    line-height: 15px;
    margin-left: 8px;
  }
}

@media screen and (max-width: 768px) {
  .search {
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (max-width: 480px) {
  .table {
    width: 90%;
  }
}
