.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 2px solid gainsboro;
  box-shadow: 24;
  border-radius: 6px;
  padding: 32px;
}

.icon {
  cursor: pointer;
}
