h2 {
  font-family: 'Roboto';
  text-align: center;
  color: rgba(175, 3, 13, 1);
}
.icon-delete {
  color: rgba(175, 3, 13, 1);
}

.search {
  margin-top: 20px;
  max-width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
